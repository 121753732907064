import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import DialActionWhats from "./Components/DialActionWhats";
import { Skeleton } from "@mui/material";
import JobBoard from "./Views/JobBoard";
import { useScreenSize } from "./hooks/useScreenSize";
import ScrollToTop from "./hooks/useScrollToTop";

// Carga diferida de los componentes
const Home = lazy(() => import("./Views/Home"));
const QualityAndSafety = lazy(() => import("./Views/QualityAndSafety"));
const NoticeOfPrivacy = lazy(() => import("./Views/NoticeOfPrivacy"));
const AboutUs = lazy(() => import("./Views/AboutUs"));
const Sitemap = lazy(() => import("./Views/Sitemap"));
const TermsAndConditions = lazy(() => import("./Views/TermsAndConditions"));

// FALTA VERIFICAR DIMENSIONS
const Vacant = lazy(() => import("./Views/Vacant/Vacant"));
const Error403 = lazy(() => import("./Views/Error/403"));
const Error404 = lazy(() => import("./Views/Error/404"));
const Error503 = lazy(() => import("./Views/Error/503"));
const FrequentQuestions = lazy(() => import("./Views/FrequentQuestions"));
const Blog = lazy(() => import("./Views/Blog"));
const DeleteAccount = lazy(() => import("./Views/DeleteAccount"));
const LandingPages = lazy(() => import("./Views/LandingPages"));
const LandingPagesCompany = lazy(() => import("./Views/LandingPagesCompany"));
const ArticleDetails = lazy(() => import("./Components/ArticleDetails"));
const WaitingcontactMovilabs = lazy(() => import("./Views/WaitingcontactMovilabs"));
const WaitingcontactMovicompany = lazy(() => import("./Views/WaitingcontactMovicompany"));

// ARTICLES
// 2023
const RevolucionandoElCuidadoDeLaSalud = lazy(() => import("./Components/NewsLetter/Article/2023/RevolucionandoElCuidadoDeLaSalud"));
const ComoReducirElRiesgoDePadecerCancerDeProstata = lazy(() => import("./Components/NewsLetter/Article/2023/ComoReducirElRiesgoDePadecerCancerDeProstata"));
const SoyDistraídoOTengoTDAH = lazy(() => import("./Components/NewsLetter/Article/2023/SoyDistraídoOTengoTDAH"));
const CancerDeMamaConcienciaPrevencion = lazy(() => import("./Components/NewsLetter/Article/2023/CancerDeMamaConcienciaPrevencion"));
// 2024
const PorQueDeboRealizarmeAnalisisClinicos = lazy(() => import("./Components/NewsLetter/Article/2024/March/PorQueDeboRealizarmeAnalisisClinicos"));
const TomaDeMuestrasEnAnalisisClinicos = lazy(() => import("./Components/NewsLetter/Article/2024/March/TomaDeMuestrasEnAnalisisClinicos"));
const TiposAnalisisClinicosGuiaCompleta = lazy(() => import("./Components/NewsLetter/Article/2024/April/TiposAnalisisClinicosGuiaCompleta"));
const ComoSuperarElMiedoALasAgujas = lazy(() => import("./Components/NewsLetter/Article/2024/April/ComoSuperarElMiedoALasAgujas"));
const BeneficiosDeEstudiosLaboratorio = lazy(() => import("./Components/NewsLetter/Article/2024/April/BeneficiosDeEstudiosLaboratorio"));
const EstudiosdeLaboratorioRecomendados = lazy(() => import("./Components/NewsLetter/Article/2024/May/EstudiosdeLaboratorioRecomendados"));
const RayosXTodoLoQueNecesitasSaber = lazy(() => import("./Components/NewsLetter/Article/2024/May/RayosXTodoLoQueNecesitasSaber"));
const TiposDeRadiografiasMasComunes = lazy(() => import("./Components/NewsLetter/Article/2024/May/TiposDeRadiografiasMasComunes"));
const RadiografiasEnDeportistas = lazy(() => import("./Components/NewsLetter/Article/2024/May/RadiografiasEnDeportistas"));
const MitosYRealidadesMasComunes = lazy(() => import("./Components/NewsLetter/Article/2024/May/MitosYRealidadesMasComunes"));
const ComoDetectarElVirusDelPapiloma = lazy(() => import("./Components/NewsLetter/Article/2024/June/ComoDetectarElVirusDelPapiloma"));
const VihSidaDiferenciasSintomasDiagnosticos = lazy(() => import("./Components/NewsLetter/Article/2024/June/VihSidaDiferenciasSintomasDiagnosticos"));
const LasSeisETSMasComunesDeMexico = lazy(() => import("./Components/NewsLetter/Article/2024/June/LasSeisETSMasComunesDeMexico"));
const ComoDetectarLaAnemia = lazy(() => import("./Components/NewsLetter/Article/2024/June/ComoDetectarLaAnemia"));
const HelicobacterPyloriComoSaberSiLoTienes = lazy(() => import("./Components/NewsLetter/Article/2024/Jule/HelicobacterPyloriComoSaberSiLoTienes"));
const SabesQueEsElLupusTeLoExplicamos = lazy(() => import("./Components/NewsLetter/Article/2024/Jule/SabesQueEsElLupus"));
const EstoyEmbarazadaSintomasDelEmbarazo = lazy(() => import("./Components/NewsLetter/Article/2024/Jule/EstoyEmbarazadaSíntomasDelEmbarazo"));
const ComoManejarElEstresDiario = lazy(() => import("./Components/NewsLetter/Article/2024/Jule/ComoManejarElEstresDiario"));
const BeneficiosImportanciaDeLactanciaMaterna = lazy(() => import("./Components/NewsLetter/Article/2024/August/BeneficiosImportanciaDeLactanciaMaterna"));
const QueEsElEstudioTPYTPT = lazy(() => import("./Components/NewsLetter/Article/2024/August/QueEsElEstudioTPYTPT"));
const DeficienciaDeVitaminaDetectaLosSntomas = lazy(() => import("./Components/NewsLetter/Article/2024/September/DeficienciaDeVitaminaDetectaLosSntomas"));
const CuidaTuSaludEstasFiestasPatriasConLosSiguientes = lazy(() => import("./Components/NewsLetter/Article/2024/September/CuidaTuSaludEstasFiestasPatriasConLosSiguientes"));
/**
 * Componente principal de la aplicación.
 * @returns {JSX.Element} Elemento JSX que representa la aplicación.
 */
const App = () => {
  /**
   * Obtiene la ruta actual del navegador y verifica si incluye una subcadena específica.
   *
   * @constant {string} rutaActual - La ruta actual de la ubicación en la ventana del navegador.
   * @constant {boolean} RUTA - Indica si la ruta actual incluye la subcadena "/landing".
   */
  const rutaActual = window.location.pathname;
  const RUTA = rutaActual.includes("/landing");
 
  const { isMobile, isTabletVertical, isTabletHorizontal, isDesktop, isXXL } =
  useScreenSize();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      {!RUTA && <Navbar phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />}
      <Suspense fallback={ <Skeleton variant="rectangular" width={isMobile ? "100%" : "100%"} height={isMobile ? "100%" : "100%"} />}>
       <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/nosotros" element={<AboutUs phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          <Route path="/:nombre/a-domicilio-con-MoviCare" element={<ArticleDetails phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          <Route path="/bolsa-de-trabajo" element={<JobBoard phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          <Route path="/solicitud-empleo" element={<Vacant phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          <Route path="/aviso-de-privacidad" element={<NoticeOfPrivacy phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          <Route path="/terminos-y-condiciones" element={<TermsAndConditions phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          <Route path="/calidad-y-seguridad" element={<QualityAndSafety phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          <Route path="/mapa-de-sitio" element={<Sitemap phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          <Route path="/preguntas-frecuentes" element={<FrequentQuestions phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog" element={<Blog phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL}  />} />
          {/* Artículos */}
          <Route path="/Blog/revolucionando-el-cuidado-de-la-salud" element={<RevolucionandoElCuidadoDeLaSalud phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Cómo-reducir-el-riesgo-de-padecer-cáncer-de-próstata" element={<ComoReducirElRiesgoDePadecerCancerDeProstata phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Soy-distraído-o-tengo-TDAH" element={<SoyDistraídoOTengoTDAH phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Cáncer-de-Mama-Conciencia-y-Prevención" element={<CancerDeMamaConcienciaPrevencion phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Por-que-debo-realizarme-análisis-clinicos" element={<PorQueDeboRealizarmeAnalisisClinicos phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />}/>
          <Route path="/Blog/toma-de-muestras-en-análisis-clinicos-todo-lo-que-necesitas-saber" element={<TomaDeMuestrasEnAnalisisClinicos phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Tipos-de-análisis-clínicos-Guía-completa" element={<TiposAnalisisClinicosGuiaCompleta phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Cómo-superar-el-miedo-a-las-agujas-durante-mis-estudios-de-laboratorio" element={<ComoSuperarElMiedoALasAgujas phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Beneficios-de-los-estudios-de-laboratorio-a-domicilio" element={<BeneficiosDeEstudiosLaboratorio phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Estudios-de-Laboratorio-Recomendados-para-Detectar-la-Diabetes" element={<EstudiosdeLaboratorioRecomendados phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Rayos-X-Todo-Lo-Que-Necesitas-Saber" element={<RayosXTodoLoQueNecesitasSaber phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Tipos-de-Radiografías-Más-Comunes-y-Para-Qué-Se-Utilizan" element={<TiposDeRadiografiasMasComunes phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Radiografías-en-deportistas-Importancia-y-prevención-de-lesiones" element={<RadiografiasEnDeportistas phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Mitos-y-realidades-más-comunes-de-los-análisis-de-laboratorio" element={<MitosYRealidadesMasComunes phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Cómo-detectar-el-virus-del-papiloma-humano-en-hombres" element={<ComoDetectarElVirusDelPapiloma phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/VIH-SIDA-diferencias-sintomas-diagnostico" element={<VihSidaDiferenciasSintomasDiagnosticos phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Las-seis-ETS-más-comunes-de-México" element={<LasSeisETSMasComunesDeMexico phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Como-detectar-la-anemia" element={<ComoDetectarLaAnemia phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Helicobacter-pylori-como-saber-si-lo-tienes" element={<HelicobacterPyloriComoSaberSiLoTienes phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Sabes-que-es-el-lupus-te-lo-explicamos" element={<SabesQueEsElLupusTeLoExplicamos phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Estoy-embarazada-sintomas-del-embarazo-que-debes-conocer" element={<EstoyEmbarazadaSintomasDelEmbarazo phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Como-manejar-el-estres-diario" element={<ComoManejarElEstresDiario phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Beneficios-e-importancia-de-la-lactancia-materna" element={<BeneficiosImportanciaDeLactanciaMaterna phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Que-es-el-Estudio-TP-y-TPT" element={<QueEsElEstudioTPYTPT phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Deficiencia-de-Vitamina-D-Detecta-los-sintomas-a-tiempo" element={<DeficienciaDeVitaminaDetectaLosSntomas phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Blog/Cuida-tu-salud-estas-Fiestas-Patrias-con-los-siguientes-consejos" element={<CuidaTuSaludEstasFiestasPatriasConLosSiguientes phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          {/* Landing campaigns */}
          <Route path="/landing_movilabs" element={<LandingPages phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/landing_moviempresas" element={<LandingPagesCompany phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Servicios-medicos-para-laboratorios-en-ciudad-de-mexico-y-area-metropolitana" element={<LandingPages phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Servicios-medicos-para-empresas-en-ciudad-de-mexico-y-area-metropolitana" element={<LandingPagesCompany phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/landing_movilabs_agradecimiento" element={<WaitingcontactMovilabs phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/landing_moviempresas_agradecimiento" element={<WaitingcontactMovicompany phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          {/* Páginas de error */}
          <Route path="/Error403" element={<Error403 phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Error404" element={<Error404 phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          <Route path="/Error503" element={<Error503 phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
          {/* Ruta para errores no encontrados */}
          <Route component={Error404} />
          <Route path="/deseo-eliminar-mi-cuenta" element={<DeleteAccount phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />} />
        </Routes>
      </Suspense>
      <DialActionWhats />
      {!RUTA && <Footer phonewidth={isMobile} isTabletVertical={isTabletVertical} isTabletHorizontal={isTabletHorizontal} isDesktop={isDesktop} xxlwidth={isXXL} />}
    </>
  );
};

export default App;

