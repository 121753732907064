import { useMemo } from "react";
import { useDimensions } from "./useDimensions";

export const useScreenSize = () => {
  const width = useDimensions("width");

  // Usamos useMemo para evitar recalcular en cada renderizado si el width no cambia
  const screenSizes = useMemo(() => {
    return {
      isMobile: width < 600, // Dispositivos móviles pequeños
      isTabletVertical: width >= 600 && width < 900, // Tabletas en vertical
      isTabletHorizontal: width >= 900 && width < 1200, // Tabletas en horizontal
      isDesktop: width >= 1200 && width <= 1800, // Escritorio normal
      isXXL: width > 1800, // Pantallas más grandes
    };
  }, [width]);

  return screenSizes;
};
